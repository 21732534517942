.social{
	position: absolute;
	right: 15px;
	min-height: 128px;
	bottom: 28px;
	@include media-min(1300) {
		right: auto;
	    left: calc((100% - 1100px)/2 + 1100px + 20px);
	}
	@include media-min(767){
		position: fixed;
		right: 20px;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
	}
	&__item{
		transition: all .5s;
	}
}
.chat {
	display: none;
}
.fb_dialog {
	@include media-max(768){
		right: 42px !important;
		bottom: 32px !important;
	}
	img{
		@include absolute-center;
	}
	// &:after,
	// &:before{
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	bottom: 0;
	// 	right: 0;
	// 	border-radius: 100%;
	// 	border: 1px solid #00A7FF;
	// }
	// &:before{
	// 	transform: scale(2);
	// 	opacity: 0.2;
	// }
	// &:after{
	// 	animation: chat 3s ease-in-out infinite;
	// }
}