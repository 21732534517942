.icon{
	cursor: pointer;
	width: 25px;
	height: 25px;
	opacity: 0.3;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	&:hover,
	&--active{
		transition: all .5s;
		opacity: 1;
	}
	&--disabled{
		background-color: red;
		pointer-events: none;
	}
	&--circle{
		border: 1px solid #fff;
		border-radius: 100%;
	}

	&--plus-bg,
	&--close-bg,
	&--cart-bg,
	&--minus-circle,
	&--plus-circle{
		background-size: cover;
	}

	&--close-bg,
	&--cart-bg{
		opacity: 1;
	}

	&--arrow{
		background-image: url('../img/icon/arrow.svg');
	}
	&--plus{
		background-image: url('../img/icon/plus.svg');
	}
	&--facebook{
		background-image: url('../img/icon/facebook.svg');
	}
	&--tripadvisor{
		background-image: url('../img/icon/tripadvisor.svg');
	}
	&--instagram{
		background-image: url('../img/icon/instagram.svg');
	}
	&--remove{
		background-image: url('../img/icon/remove.svg');
	}
	&--paper{
		background-image: url('../img/icon/papper.svg');
	}
	&--paper-two{
		background-image: url('../img/icon/papper-two.svg');
	}
	&--paper-three{
		background-image: url('../img/icon/papper-three.svg');
	}
	&--sous{
		background-image: url('../img/icon/sous.svg');
	}
	&--sous-two{
		background-image: url('../img/icon/sous-two.svg');
	}
	&--sous-three{
		background-image: url('../img/icon/sous-three.svg');
	}
	&--info{
		background-image: url('../img/icon/info.svg');
	}
	&--minus-circle{
		background-image: url('../img/icon/minus-circle.svg');
	}
	&--plus-circle{
		background-image: url('../img/icon/plus-circle.svg');
	}
	&--plus-bg{
		background-image: url('../img/icon/plus-circle-bg.svg');
	}
	&--close-bg{
		background-image: url('../img/icon/close-circle-bg.svg');
	}
	&--cart-bg{
		background-image: url('../img/icon/arrow-circle.svg');
	}
}