/* Plugins */
/* Base */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700");
@font-face {
  font-family: "firago";
  src: url("../fonts/firago/FiraGO-Light.eot?v=2.0.1#iefix") format("embedded-opentype"), url("../fonts/firago/FiraGO-Light.woff") format("woff"), url("../fonts/firago/FiraGO-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "firago";
  src: url("../fonts/firago/FiraGO-Regular.eot?v=2.0.1#iefix") format("embedded-opentype"), url("../fonts/firago/FiraGO-Regular.woff") format("woff"), url("../fonts/firago/FiraGO-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "firago";
  src: url("../fonts/firago/FiraGO-Medium.eot?v=2.0.1#iefix") format("embedded-opentype"), url("../fonts/firago/FiraGO-Medium.woff") format("woff"), url("../fonts/firago/FiraGO-Bold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "firago";
  src: url("../fonts/firago/FiraGO-SemiBold.eot?v=2.0.1#iefix") format("embedded-opentype"), url("../fonts/firago/FiraGO-SemiBold.woff") format("woff"), url("../fonts/firago/FiraGO-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "firago";
  src: url("../fonts/firago/FiraGO-Bold.eot?v=2.0.1#iefix") format("embedded-opentype"), url("../fonts/firago/FiraGO-Bold.woff") format("woff"), url("../fonts/firago/FiraGO-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

html[lang="en"] body {
  font-family: "Poppins", sans-serif; }

html[lang="ge"] body {
  font-family: "firago", sans-serif; }

body {
  margin: 0;
  padding: 0;
  text-transform: none;
  color: #C9C9C9;
  line-height: 1.5;
  font-weight: 300;
  font-size: 14px;
  background-color: #151515;
  overflow-x: hidden;
  overflow-y: auto; }
  body::-webkit-scrollbar {
    width: 10px; }
  body::-webkit-scrollbar-thumb {
    border: 1px solid 1px solid transparent;
    background-clip: content-box;
    background-color: #666666; }
  body::-webkit-scrollbar-track {
    border: 0;
    background-color: #d4d4d4; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

marquee {
  width: 100%; }

ul, li {
  list-style: none; }

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none; }

input[type="text"],
input[type="password"],
input[type="email"],
inputm
textarea {
  outline: none;
  appearance: none;
  border-radius: 0; }

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
label,
a {
  outline: none;
  cursor: pointer;
  transition: all 0.5s; }

img {
  max-width: 100%;
  height: auto;
  display: block; }

table {
  width: 100%; }

td,
tr,
form {
  margin: 0;
  padding: 0; }

a {
  color: #C9C9C9;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a,
span,
div,
button {
  outline: none !important; }

hr {
  border: 0;
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: #000; }

* {
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

[class^="col-"] {
  position: relative;
  padding-left: 15px;
  padding-right: 15px; }

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }
  .col-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .col-2 {
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .col-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .col-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .col-5 {
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .col-6 {
    max-width: 50%;
    flex: 0 0 50%; }
  .col-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .col-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .col-9 {
    max-width: 75%;
    flex: 0 0 75%; }
  .col-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .col-11 {
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .col-12 {
    max-width: 100%;
    flex: 0 0 100%; }

@media (min-width: 576px) {
  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .col-sm-2 {
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .col-sm-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .col-sm-5 {
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%; }
  .col-sm-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .col-sm-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%; }
  .col-sm-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .col-sm-11 {
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%; } }

@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .col-md-2 {
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .col-md-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .col-md-5 {
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%; }
  .col-md-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .col-md-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%; }
  .col-md-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .col-md-11 {
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .col-lg-2 {
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .col-lg-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .col-lg-5 {
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%; }
  .col-lg-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .col-lg-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%; }
  .col-lg-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .col-lg-11 {
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%; }
  .col-xl-2 {
    max-width: 16.66667%;
    flex: 0 0 16.66667%; }
  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%; }
  .col-xl-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%; }
  .col-xl-5 {
    max-width: 41.66667%;
    flex: 0 0 41.66667%; }
  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%; }
  .col-xl-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%; }
  .col-xl-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%; }
  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%; }
  .col-xl-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%; }
  .col-xl-11 {
    max-width: 91.66667%;
    flex: 0 0 91.66667%; }
  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%; } }

.blug-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("../img/coming-soon.jpg");
  background-size: cover;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center; }
  .blug-bg h1 {
    font-size: 70px;
    color: white;
    text-transform: uppercase;
    text-shadow: 4px 4px 6px black; }

body:before, body:after {
  content: '';
  position: fixed;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

body:before {
  width: 130px;
  height: 230px;
  top: -130px;
  right: -10px;
  background-image: url("../img/body-top.svg"); }

body:after {
  width: 200px;
  height: 250px;
  left: -20px;
  bottom: -130px;
  background-image: url("../img/body-bottom.svg"); }

body * {
  z-index: 1; }

body .cloned__article-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center; }

.container-fluid,
.container,
.section,
.main {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.container {
  padding: 0 15px 0 15px;
  min-width: 320px;
  max-width: 1130px; }
  @media only screen and (min-width: 1200px) {
    .container {
      padding: 0 15px; } }

.main {
  padding: 20px 0; }
  .main__nav {
    padding-bottom: 20px; }

.call-us {
  position: fixed;
  cursor: pointer;
  z-index: 99;
  display: flex;
  align-items: center;
  bottom: 90px;
  left: 25px; }
  @media only screen and (max-width: 992px) {
    .call-us {
      bottom: 10px;
      left: 10px; } }
  .call-us .call-icon {
    display: flex;
    border: 4px solid #FF4200;
    padding: 15px;
    border-radius: 50%;
    margin-right: 14px; }
    @media only screen and (max-width: 992px) {
      .call-us .call-icon {
        padding: 10px;
        background-color: #151515; } }
  .call-us:hover p.font {
    display: block; }
  .call-us p.font {
    display: none; }

.district {
  display: none;
  overflow-y: auto;
  max-height: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #656565;
  background-color: #191919;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  top: 53px;
  z-index: 10; }
  .district::-webkit-scrollbar {
    width: 5px; }
  .district::-webkit-scrollbar-thumb {
    border: 1px solid 1px solid transparent;
    background-clip: content-box;
    background-color: #666666; }
  .district::-webkit-scrollbar-track {
    border: 0;
    background-color: #d4d4d4; }
  @media only screen and (max-width: 991px) {
    .district {
      max-height: 265px; } }
  @media only screen and (min-height: 650px) {
    .district {
      min-height: 305px; } }
  @media only screen and (min-height: 750px) {
    .district {
      min-height: 345px; } }
  .district__item {
    padding: 7px 0;
    text-align: center;
    cursor: pointer; }
    .district__item:hover {
      color: #FF4200; }

@media only screen and (max-width: 768px) {
  .page {
    position: relative; } }

.page--main {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media only screen and (max-width: 991px) {
    .page--main {
      height: auto;
      min-height: 100vh;
      overflow-y: auto; } }
  @media only screen and (max-width: 991px) {
    .page--main .special-offer-wrap {
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 70px; } }
  .page--main .special-offer-wrap .special-offer-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    @media only screen and (max-width: 991px) {
      .page--main .special-offer-wrap .special-offer-close {
        display: inline-block;
        right: 20%;
        position: absolute;
        font-size: 48px;
        font-family: 'Poppins';
        line-height: 48px; } }
    @media only screen and (max-width: 768px) {
      .page--main .special-offer-wrap .special-offer-close {
        right: 5%; } }
  .page--main .special-offer {
    transition: all 1s;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .page--main .special-offer {
        max-width: 100%; } }
    .page--main .special-offer:before {
      content: "";
      display: block;
      position: absolute;
      width: 492px;
      height: 777px;
      background: transparent url("../img/special-bg.png") no-repeat center;
      background-size: contain;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      animation: special-bg-rotate 3s linear; }
    @media only screen and (max-width: 991px) {
      .page--main .special-offer .article {
        width: 264px; } }
    @media only screen and (max-width: 768px) {
      .page--main .special-offer .article {
        margin-bottom: 20px; } }
    @media only screen and (max-width: 991px) {
      .page--main .special-offer .text-center {
        width: 70%;
        margin: 0 auto; } }
    @media only screen and (max-width: 768px) {
      .page--main .special-offer .text-center {
        width: 90%; } }
    .page--main .special-offer .text-center .font--md {
      margin-bottom: 5px; }
    .page--main .special-offer .text-center .font--sm {
      padding: 0 15%; }
  .page--main .main .container {
    position: relative; }
    .page--main .main .container:before {
      content: 'Mochi';
      position: absolute;
      left: -100px;
      right: 0;
      top: 50%;
      font-family: "Poppins";
      font-size: 310px;
      font-weight: bold;
      color: #fff;
      opacity: 0.03;
      transform: translateY(-50%); }
      @media only screen and (max-width: 767px) {
        .page--main .main .container:before {
          background-image: none; } }
    .page--main .main .container .row--big {
      min-height: 400px;
      background-image: url("../img/category/img/0.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: ease-in-out 0.5s; }
      @media only screen and (max-width: 991px) {
        .page--main .main .container .row--big {
          background-image: none;
          width: 100%; } }
    .page--main .main .container .img--big {
      max-width: 400px;
      max-height: 400px;
      transition: all 1s;
      margin-right: 0;
      margin-left: auto; }

.page--profile .order__name {
  align-items: center; }

.page--profile .tab {
  max-width: 700px; }
  .page--profile .tab__body {
    padding-left: 0;
    padding-right: 0; }

.page--category .sort {
  margin-top: 5px; }

@media only screen and (min-width: 1200px) {
  .page--category .articles-wrapper {
    margin: 0 -40px; } }

.page--category .articles-wrapper .article__body > div:first-child {
  margin-bottom: 10px; }

.page--category .articles-wrapper .article__name, .page--category .articles-wrapper .article__price {
  font-weight: 600; }

.page--category .articles-wrapper .article .icon--plus-bg {
  margin-right: 0; }

.page--order .popup__list-order {
  max-width: 500px;
  margin: 0 auto; }
  @media only screen and (max-width: 767px) {
    .page--order .popup__list-order li {
      margin-bottom: 0; }
      .page--order .popup__list-order li > * {
        margin: 10px 0; }
      .page--order .popup__list-order li:last-child .button {
        margin: 10px 0; } }

@media only screen and (max-width: 350px) {
  .page--order .address__footer > p {
    font-size: 10px; } }

.page--order .delivery-btn-row {
  z-index: 8; }
  .page--order .delivery-btn-row .delivery-btn-wrap {
    z-index: 8; }
  .page--order .delivery-btn-row .district {
    max-width: 90%;
    left: 5%; }

.page--contact .contact-info {
  margin-bottom: 10px; }
  .page--contact .contact-info > * {
    margin: 10px 43px 10px 0; }
    .page--contact .contact-info > * img {
      margin-right: 10px; }

.page--category .main-wrapper, .page--ty .main-wrapper, .page--404 .main-wrapper, .page--contact .main-wrapper, .page--faq .main-wrapper, .page--order .main-wrapper, .page--profile .main-wrapper {
  min-height: calc(100vh - 106px); }

.page--thankyou .ty-inner {
  padding: 30px 10px; }
  .page--thankyou .ty-inner h1:first-child {
    margin-bottom: 20px; }

.hr {
  display: block; }
  .hr--vertical {
    height: 50px;
    width: 2px;
    margin: 0 20px;
    background-color: #656565; }
  .hr--line {
    width: 100%;
    height: 2px;
    margin-left: 35px;
    margin-right: 10px;
    background-color: #656565; }
  .hr--dashed {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #656565; }
    .hr--dashed:first-child {
      margin-right: 5px; }
    .hr--dashed:last-child {
      margin-left: 5px; }

.flex-container {
  display: flex; }

.flex-dir-row {
  flex-wrap: wrap; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-no-wrap {
  flex-wrap: nowrap; }

.align-left {
  justify-content: flex-start; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-middle {
  align-items: center; }

.align-stretch {
  align-items: stretch; }

.align-baseline {
  align-items: baseline; }

.align-self-top {
  align-self: flex-start; }

.align-self-bottom {
  align-self: flex-end; }

.align-self-middle {
  align-self: center; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center; }

.text {
  margin: 10px 0; }
  .text-left {
    text-align: left; }
  .text-right {
    text-align: right; }
  .text-center {
    text-align: center; }
  .text-justify {
    text-align: justify; }
  .text-uppercase {
    text-transform: uppercase; }
  .text-lowercase {
    text-transform: lowercase; }
  .text-capital {
    text-transform: capitalize; }
  .text-underline {
    text-decoration: underline; }
  .text-cursive {
    font-style: italic; }
  .text-nowrap {
    white-space: nowrap; }
  .text-ellipsis {
    max-width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis; }

.overflow-hidden {
  overflow: hidden; }

.overflow-visible {
  overflow: visible; }

.margin {
  margin: 0 !important; }

.padding {
  padding: 0 !important; }

.relative {
  position: relative; }

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

@keyframes chat {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(2); }
  100% {
    transform: scale(1); } }

@keyframes special-bg-rotate {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

/* Typography */
html[lang="en"] body .font {
  font-family: "Poppins", sans-serif; }

html[lang="ge"] body .font {
  font-family: "firago", sans-serif; }

.font {
  color: #C9C9C9;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300; }
  .font--primary {
    color: #FF4200; }
  .font--second {
    color: #656565; }
  .font--green {
    color: #91D176; }
  .font--white {
    color: #fff; }
  .font--light {
    font-weight: 300; }
  .font--regular {
    font-weight: 400; }
  .font--medium {
    font-weight: 500; }
  .font--semibold {
    font-weight: 600; }
  .font--bold {
    font-weight: 700; }
  .font--sm {
    font-size: 12px; }
  .font--md {
    font-size: 16px; }
  .font--lg {
    font-size: 18px; }

html[lang="en"] body .h1, html[lang="en"] body .h2, html[lang="en"] body .h3, html[lang="en"] body .h4, html[lang="en"] body .h5, html[lang="en"] body .h6 {
  font-family: "Poppins", sans-serif; }

html[lang="ge"] body .h1, html[lang="ge"] body .h2, html[lang="ge"] body .h3, html[lang="ge"] body .h4, html[lang="ge"] body .h5, html[lang="ge"] body .h6 {
  font-family: "firago", sans-serif; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #C9C9C9;
  line-height: 1.5;
  font-weight: 300;
  text-transform: uppercase; }
  .h1--primary,
  .h2--primary,
  .h3--primary,
  .h4--primary,
  .h5--primary,
  .h6--primary {
    color: #FF4200; }
  .h1--second,
  .h2--second,
  .h3--second,
  .h4--second,
  .h5--second,
  .h6--second {
    color: #656565; }
  .h1--white,
  .h2--white,
  .h3--white,
  .h4--white,
  .h5--white,
  .h6--white {
    color: #fff; }
  .h1--light,
  .h2--light,
  .h3--light,
  .h4--light,
  .h5--light,
  .h6--light {
    font-weight: 300; }
  .h1--regular,
  .h2--regular,
  .h3--regular,
  .h4--regular,
  .h5--regular,
  .h6--regular {
    font-weight: 400; }
  .h1--medium,
  .h2--medium,
  .h3--medium,
  .h4--medium,
  .h5--medium,
  .h6--medium {
    font-weight: 500; }
  .h1--semibold,
  .h2--semibold,
  .h3--semibold,
  .h4--semibold,
  .h5--semibold,
  .h6--semibold {
    font-weight: 600; }
  .h1--bold,
  .h2--bold,
  .h3--bold,
  .h4--bold,
  .h5--bold,
  .h6--bold {
    font-weight: 700; }

.h1 {
  font-size: 44px; }

.h2 {
  font-size: 42px; }

.h3 {
  font-size: 38px; }

.h4 {
  font-size: 32px; }

.h5 {
  font-size: 24px; }

.h6 {
  font-size: 20px; }

/* Base modules */
.button {
  border-radius: 5px;
  border: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .button:hover {
    transform: scale(1.05); }
  .button img {
    margin: 0 10px; }
  .button--primary {
    color: #fff;
    border: 1px solid #656565;
    background-color: #191919;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75); }
    .button--primary:hover {
      border-color: #FF4200;
      /* img{
				opacity: 1;
			} */ }
  .button--facebook {
    border: 1px solid #4667AC;
    background-color: #191919;
    position: relative;
    padding-left: 50px;
    color: #C9C9C9;
    font-size: 16px;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
    font-weight: 300; }
    .button--facebook:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50px;
      background-color: #4667AC;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/icon/facebook.svg"); }
    .button--facebook:hover {
      background-color: #4667AC; }
  .button--verify {
    color: #fff;
    background-color: #3E3E3E; }
    .button--verify:hover {
      background-color: #313131; }

.field {
  resize: none;
  border: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0); }

.checkbox {
  display: none; }
  .checkbox + label {
    position: relative;
    padding-left: 25px; }
    .checkbox + label:before {
      content: "";
      width: 15px;
      height: 15px;
      line-height: 1;
      text-align: center;
      transition: all .5s;
      border: 1px solid #C9C9C9;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0); }
  .checkbox:checked + label:before {
    background-color: #C9C9C9;
    color: #C9C9C9; }

/* Parts modules */
.nav {
  width: 100%;
  height: 110px;
  z-index: 9;
  transition: all 0.5s ease-out 0s; }
  @media only screen and (max-width: 767px) {
    .nav {
      height: 100px; } }
  .nav .container {
    height: inherit;
    position: relative;
    padding-right: 15px; }
    .nav .container:before {
      content: '';
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 1px;
      background-color: #656565; }
  .nav--default {
    position: relative; }
  .nav--fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8); }
  @media only screen and (max-width: 767px) {
    .nav--default, .nav--fixed {
      background-color: rgba(0, 0, 0, 0.8); } }
  @media only screen and (max-width: 767px) {
    .nav--default .navigation, .nav--fixed .navigation {
      background-color: rgba(0, 0, 0, 0.8); } }
  .nav #logoutForm .menu__item {
    font-size: 12px;
    font-weight: 600;
    margin: 0; }

.logo {
  display: block;
  min-width: 56px;
  height: 56px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative; }
  .logo--active:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.5px;
    height: 2px;
    border-radius: 2px;
    background-color: #FF4200; }

.menu {
  margin-left: 10px; }
  .menu__item {
    text-align: center;
    white-space: nowrap;
    margin: 0 10px; }
    @media only screen and (max-width: 767px) {
      .menu__item {
        margin: 0 7px; } }
    .menu__item:first-child {
      margin-left: 0; }
      @media only screen and (max-width: 767px) {
        .menu__item:first-child {
          margin-left: 0; } }
    @media only screen and (min-width: 992px) {
      .menu__item:last-child {
        margin-right: 0; } }
    .menu__item span {
      font-size: 12px;
      font-weight: 600; }
    .menu__item .link {
      display: block;
      padding: 10px; }
      @media only screen and (min-width: 767px) {
        .menu__item .link {
          padding: 7px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .menu__item .link {
          padding: 0; } }
      @media only screen and (max-width: 767px) {
        .menu__item .link {
          padding: 0 10px; } }
      .menu__item .link:hover, .menu__item .link--active {
        color: #FF4200; }
        .menu__item .link:hover svg, .menu__item .link--active svg {
          fill: #FF4200;
          transition: all .5s; }
      .menu__item .link span {
        display: block; }
      .menu__item .link svg {
        display: block;
        margin: 10px auto;
        fill: #C9C9C9; }
    .menu__item.lang__switcher {
      padding-top: 5px;
      min-width: 18px; }
      .menu__item.lang__switcher a:first-child {
        margin-bottom: 13px; }
      .menu__item.lang__switcher a.active, .menu__item.lang__switcher a:hover {
        font-weight: 700;
        color: #FF4200; }

.breadcrumbs {
  margin: 10px 0; }
  .breadcrumbs__item {
    padding: 0 20px;
    position: relative; }
    .breadcrumbs__item:hover:not(:last-child), .breadcrumbs__item--active:not(:last-child) {
      color: #FFF; }
    .breadcrumbs__item:first-child {
      padding-left: 0; }
    .breadcrumbs__item:last-child {
      padding-right: 0;
      color: #FFF; }
    .breadcrumbs__item:not(:last-child) {
      color: #5A5A5A; }
      .breadcrumbs__item:not(:last-child):after {
        content: '';
        width: 10px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../img/icon/arrow.svg");
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%); }

.article {
  width: 100%;
  margin: 30px auto;
  max-width: 204px;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 576px) {
    .article {
      height: auto;
      max-width: 260px;
      border-bottom: 1px solid #656565;
      padding: 30px 0px; } }
  .article--special {
    position: relative;
    padding: 30px;
    max-width: 264px;
    min-height: 330px;
    border-radius: 20px;
    border: 1px solid #FF4200;
    background-color: #151515; }
    .article--special .icon--plus-bg {
      position: absolute;
      bottom: 0;
      left: 50%;
      opacity: 1;
      margin: 0;
      transform: translate(-50%, 55%); }
    .article--special .icon--sous {
      margin-bottom: 0; }
    .article--special__header .img {
      margin-top: -20px; }
    .article--special__info {
      display: flex;
      align-items: center;
      flex-direction: row-reverse; }
      .article--special__info > * {
        margin-bottom: 10px;
        margin-top: 0; }
      .article--special__info .icon--info {
        margin-top: -2px; }
    .article--special__name, .article--special__count {
      font-size: 13px; }
    .article--special__price {
      font-size: 19px;
      margin-right: auto; }
    .article--special__price-old {
      margin-right: 0;
      display: none; }
    .article--special__body {
      padding: 0; }
      .article--special__body > * {
        margin-bottom: 10px; }
      .article--special__body .flex-container:last-child {
        margin-bottom: 0; }
  .article--unavailable *:not(.article__unavailable) {
    color: #656565; }
  .article--unavailable .icon,
  .article--unavailable .img {
    pointer-events: none; }
  .article--no-options .icon {
    pointer-events: none;
    opacity: 0.1; }
  .article__header {
    position: relative;
    flex-grow: 1; }
    .article__header .img {
      position: relative;
      margin-top: -35px;
      height: 204px; }
      .article__header .img .article__img {
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
      @media only screen and (max-width: 576px) {
        .article__header .img {
          margin-top: -20px; } }
      .article__header .img--disabled {
        pointer-events: none; }
      .article__header .img:hover .article__link {
        display: block; }
      .article__header .img:hover .article__img {
        opacity: 0.3; }
    .article__header .article__img {
      margin: 0 auto; }
    .article__header .article__link {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/icon/zoom.svg"); }
  .article__body {
    margin-top: -30px; }
    .article__body .flex-container:last-child {
      min-height: 25px; }
      .article__body .flex-container:last-child > p {
        line-height: 1; }
  .article__options {
    position: relative;
    z-index: 2; }
    .article__options .toggle-show {
      position: relative;
      z-index: 2; }
    .article__options .icon--sous {
      background-size: 9px;
      z-index: 2; }
  .article__toggle {
    width: 0;
    overflow: hidden;
    position: absolute;
    left: 35px;
    top: 0;
    opacity: 0;
    transition: all .5s; }
    .article__toggle--active {
      opacity: 1;
      width: auto;
      overflow: visible; }
  .article__count {
    margin-left: 30px; }
  .article__info {
    z-index: 2; }
  .article__price-old {
    text-decoration: line-through;
    margin-right: 10px;
    margin-left: 10px; }
  .article__unavailable {
    margin-left: auto; }
  .article__delivery:hover {
    color: #FF4200; }
  .article .icon {
    margin-bottom: 10px;
    margin-right: 10px; }
    .article .icon--plus-bg {
      margin-bottom: 0; }
    .article .icon--active.remove-selection {
      background-image: url("../img/icon/close-icon.svg");
      background-size: 100%; }
    .article .icon--info {
      background-position: 100% 50%; }

.tooltip {
  margin-left: auto;
  margin-bottom: 10px; }
  .tooltip .icon--info {
    margin: 0;
    margin-left: auto;
    position: relative;
    z-index: 2; }
  .tooltip span {
    display: block;
    color: #fff;
    text-align: center;
    display: none;
    position: absolute;
    min-width: 80px;
    top: 30px;
    right: 0;
    line-height: 1.6;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: 10px;
    border-radius: 5px; }

.social {
  position: absolute;
  right: 15px;
  min-height: 128px;
  bottom: 28px; }
  @media only screen and (min-width: 1300px) {
    .social {
      right: auto;
      left: calc((100% - 1100px)/2 + 1100px + 20px); } }
  @media only screen and (min-width: 767px) {
    .social {
      position: fixed;
      right: 20px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%); } }
  .social__item {
    transition: all .5s; }

.chat {
  display: none; }

@media only screen and (max-width: 768px) {
  .fb_dialog {
    right: 42px !important;
    bottom: 32px !important; } }

.fb_dialog img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.error {
  max-width: 700px;
  margin: 20px auto; }

.faq {
  cursor: pointer;
  border: 1px solid #656565;
  border-radius: 10px;
  margin: 20px 0 20px 0;
  padding: 20px 20px 20px 30px; }
  @media only screen and (max-width: 767px) {
    .faq {
      padding: 10px; } }
  .faq:first-child {
    margin-top: 0; }
  .faq:last-child {
    margin-bottom: 0; }
  .faq--active .faq__header:after {
    transform: rotateZ(270deg); }
  .faq__header {
    position: relative;
    padding-right: 15px; }
    .faq__header:after {
      content: '';
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../img/icon/arrow.svg");
      height: 10px;
      width: 6px;
      top: 6px;
      right: 0;
      transition: all .5s;
      transform: rotateZ(90deg); }
    .faq__header hr {
      height: 20px;
      width: 1px;
      margin: 0 20px;
      background-color: #656565; }
      @media only screen and (max-width: 767px) {
        .faq__header hr {
          margin: 0 10px; } }
  .faq__body {
    padding: 10px 55px 0 55px;
    display: none; }
    @media only screen and (max-width: 767px) {
      .faq__body {
        padding: 10px; } }

.category {
  padding: 10px 10px 0 10px;
  margin: 0 20px;
  position: relative; }
  .category--small {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer; }
    .category--small.category-inactive {
      opacity: 0.3;
      cursor: default; }
    .category--small:before, .category--small:after {
      content: '';
      position: absolute;
      bottom: 0; }
    .category--small:before {
      height: 2px;
      left: 0;
      right: 0;
      width: 100%;
      transform: scaleX(0);
      border-radius: 2px;
      background-color: #FF4200; }
    .category--small:after {
      display: none;
      left: 50%;
      transform: translateX(-50%);
      border: 7px solid transparent;
      border-bottom: 5px solid #FF4200; }
    .category--small:hover *, .category--small.category--active * {
      transition: all .5s;
      opacity: 1; }
    .category--small:hover:after, .category--small.category--active:after {
      display: block; }
    .category--small:hover:before, .category--small.category--active:before {
      transition: all .5s;
      transform: scaleX(1); }
    @media only screen and (max-width: 767px) {
      .category--small {
        width: 50%;
        margin: 0;
        height: auto;
        flex: 1 1 50%;
        padding: 20px 0; }
        .category--small:before {
          width: 50%;
          margin: 0 auto; } }
    .category--small .category__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px; }
      @media only screen and (max-width: 767px) {
        .category--small .category__wrapper {
          height: 70px; } }
    .category--small .category__img {
      max-width: 40px;
      max-height: 40px; }
      @media only screen and (max-width: 767px) {
        .category--small .category__img {
          opacity: 1;
          width: 70px; } }
    .category--small .category__text {
      margin-top: 10px;
      white-space: nowrap;
      font-size: 12px; }
      @media only screen and (max-width: 767px) {
        .category--small .category__text {
          opacity: 1; } }
  .category--big {
    max-width: 140px;
    margin: 20px 0;
    padding: 0;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .category--big {
        max-width: 100%; } }
    .category--big.category-inactive {
      opacity: 0.3;
      cursor: default; }
    .category--big .category__wrapper {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center; }
    .category--big .category__img {
      max-height: 80px;
      max-width: 80px; }
    .category--big:hover *, .category--big.category--active * {
      transition: all .5s;
      color: #FF4200; }
  .category__img {
    max-width: 100%;
    margin: 0 auto; }

.input-wrapper {
  position: relative;
  width: 100%;
  height: 50px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #191919;
  border: 1px solid #656565; }
  .input-wrapper--error:after, .input-wrapper--approve:after {
    content: '';
    height: 16px;
    width: 16px;
    z-index: 2;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center center; }
  .input-wrapper--error .input-wrapper__field, .input-wrapper--approve .input-wrapper__field {
    padding-right: 35px;
    position: relative;
    z-index: 3; }
  .input-wrapper--error {
    border-color: #FF4200; }
    .input-wrapper--error:after {
      background-image: url("../img/icon/error.svg"); }
  .input-wrapper--approve:after {
    background-image: url("../img/icon/approve.svg"); }
  .input-wrapper--new-address {
    padding-right: 100px;
    overflow: visible; }
  .input-wrapper__text {
    position: absolute;
    transform: translateY(-50%);
    z-index: 2;
    top: 50%;
    left: 15px;
    font-size: 12px;
    color: #656565;
    transition: all .5s; }
    .input-wrapper__text--active {
      transform: translateY(-102%); }
  .input-wrapper__field {
    padding-top: 26px; }
  .input-wrapper__options {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0; }
    .input-wrapper__options * {
      margin: 0 5px; }
    .input-wrapper__options hr {
      height: 30px;
      width: 1px; }
    .input-wrapper__options .adress-link:hover img, .input-wrapper__options .adress-link--active img {
      opacity: 1; }
    .input-wrapper__options .adress-link img {
      transition: all .5s;
      margin: 0;
      opacity: 0.4; }

.tab {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px; }
  .tab .text-left {
    width: 10%;
    flex: 1 1 10%; }
    @media only screen and (max-width: 767px) {
      .tab .text-left {
        flex: 1 1 100%;
        width: 100%;
        margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .tab .hr.hr--vertical {
      display: none; } }
  .tab .link {
    width: 30%;
    flex: 1 1 30%;
    opacity: 0.4;
    margin-right: 20px; }
    @media only screen and (max-width: 576px) {
      .tab .link {
        flex: 1 1 100%;
        width: 100%;
        margin: 10px 0 !important; }
        .tab .link--first {
          order: 1; }
        .tab .link--second {
          order: 3; } }
    .tab .link:hover, .tab .link--active {
      opacity: 1; }
  .tab__body {
    display: none;
    width: 100%;
    flex: 1 1 100%;
    padding: 20px; }
    @media only screen and (max-width: 767px) {
      .tab__body {
        margin: 10px 0;
        padding: 0 10px; }
        .tab__body#tab-profile {
          order: 4; }
        .tab__body#tab-order {
          order: 2; } }
    @media only screen and (max-width: 767px) {
      .tab__body .row [class^="col-"] {
        margin-bottom: 20px; }
        .tab__body .row [class^="col-"]:last-child {
          margin-bottom: 0; } }

.terms {
  padding-top: 30px;
  padding-right: 30px; }
  .terms__block {
    margin-bottom: 30px; }
    .terms__block p {
      margin-bottom: 15px; }

.order {
  min-height: 70px;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid #656565; }
  @media only screen and (max-width: 567px) {
    .order {
      flex-direction: column; }
      .order div {
        flex: 1 1 100%;
        width: 100%;
        text-align: left; }
        .order div img {
          margin: 0 auto; }
        .order div:after {
          display: none; } }
  .order__img, .order__name, .order__price, .order__date {
    position: relative;
    padding: 10px; }
  .order__img:after, .order__name:after, .order__price:after {
    content: '';
    height: 45px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    border-right: 1px dashed #656565; }
  .order__img {
    width: 20%;
    flex: 1 1 20%;
    padding: 0 20px; }
    .order__img a {
      display: block; }
  .order__name {
    width: 40%;
    flex: 1 1 40%; }
  .order__price {
    width: 20%;
    flex: 1 1 20%; }
  .order__date {
    width: 20%;
    flex: 1 1 20%;
    border-right: 0; }

@media only screen and (max-width: 567px) {
  .order--multiply {
    flex-direction: column; } }

.order--multiply .order {
  border: 0;
  position: relative; }
  .order--multiply .order:before {
    content: '';
    height: 1px;
    bottom: 0;
    left: 10px;
    right: 0;
    position: absolute;
    border-top: 1px dashed #656565; }
    @media only screen and (max-width: 567px) {
      .order--multiply .order:before {
        left: 0;
        right: 0; } }
  .order--multiply .order:last-child:before {
    display: none; }

.order--multiply div:after {
  display: none; }

.order--multiply .left {
  width: 80%;
  flex: 1 1 80%; }
  @media only screen and (max-width: 567px) {
    .order--multiply .left {
      width: 100%;
      flex: 1 1 100%; } }

.order--multiply .right {
  width: 20%;
  flex: 1 1 20%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .order--multiply .right:before {
    content: '';
    left: 0;
    top: 10px;
    bottom: 10px;
    position: absolute;
    border-right: 1px dashed #656565; }
  @media only screen and (max-width: 567px) {
    .order--multiply .right {
      width: 100%;
      flex: 1 1 100%;
      padding: 10px;
      align-items: flex-start; }
      .order--multiply .right:before {
        right: 0;
        top: 0;
        bottom: auto;
        border-right: none;
        border-top: 1px dashed #656565; } }

.icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  opacity: 0.3;
  background-repeat: no-repeat;
  background-position: 50% 50%; }
  .icon:hover, .icon--active {
    transition: all .5s;
    opacity: 1; }
  .icon--disabled {
    background-color: red;
    pointer-events: none; }
  .icon--circle {
    border: 1px solid #fff;
    border-radius: 100%; }
  .icon--plus-bg, .icon--close-bg, .icon--cart-bg, .icon--minus-circle, .icon--plus-circle {
    background-size: cover; }
  .icon--close-bg, .icon--cart-bg {
    opacity: 1; }
  .icon--arrow {
    background-image: url("../img/icon/arrow.svg"); }
  .icon--plus {
    background-image: url("../img/icon/plus.svg"); }
  .icon--facebook {
    background-image: url("../img/icon/facebook.svg"); }
  .icon--tripadvisor {
    background-image: url("../img/icon/tripadvisor.svg"); }
  .icon--instagram {
    background-image: url("../img/icon/instagram.svg"); }
  .icon--remove {
    background-image: url("../img/icon/remove.svg"); }
  .icon--paper {
    background-image: url("../img/icon/papper.svg"); }
  .icon--paper-two {
    background-image: url("../img/icon/papper-two.svg"); }
  .icon--paper-three {
    background-image: url("../img/icon/papper-three.svg"); }
  .icon--sous {
    background-image: url("../img/icon/sous.svg"); }
  .icon--sous-two {
    background-image: url("../img/icon/sous-two.svg"); }
  .icon--sous-three {
    background-image: url("../img/icon/sous-three.svg"); }
  .icon--info {
    background-image: url("../img/icon/info.svg"); }
  .icon--minus-circle {
    background-image: url("../img/icon/minus-circle.svg"); }
  .icon--plus-circle {
    background-image: url("../img/icon/plus-circle.svg"); }
  .icon--plus-bg {
    background-image: url("../img/icon/plus-circle-bg.svg"); }
  .icon--close-bg {
    background-image: url("../img/icon/close-circle-bg.svg"); }
  .icon--cart-bg {
    background-image: url("../img/icon/arrow-circle.svg"); }

.sort {
  margin-top: 40px; }
  .sort__item {
    margin-right: 30px; }
    .sort__item:hover, .sort__item--active {
      text-decoration: underline;
      color: #FF4200; }

.cart {
  position: fixed;
  width: 500px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999;
  background-color: #151515;
  transition: all .5s;
  transform: translateX(110%);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media only screen and (max-width: 576px) {
    .cart {
      width: 320px; } }
  .cart--active {
    transform: translateX(0); }
  .cart__close {
    height: 40px;
    width: 40px;
    transform: translateX(-50%); }
    @media only screen and (max-width: 576px) {
      .cart__close {
        transform: translateX(0);
        margin: 0 10px; } }
  .cart__body {
    flex-grow: 1;
    max-height: calc(100% - 307px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px; }
    .cart__body::-webkit-scrollbar {
      width: 7px; }
    .cart__body::-webkit-scrollbar-thumb {
      border: 1px solid 1px solid transparent;
      background-clip: content-box;
      background-color: #000; }
    .cart__body::-webkit-scrollbar-track {
      border: 0;
      background-color: #d4d4d4; }
    @media (min-height: 600px) and (max-height: 800px) {
      .cart__body {
        max-height: calc(100% - 240px); } }
    @media only screen and (max-width: 767px) {
      .cart__body {
        max-height: calc(100% - 150px); } }
    .cart__body .tooltip {
      z-index: 99; }
      .cart__body .tooltip span {
        left: 0;
        right: auto; }
  .cart__header {
    min-height: 115px;
    padding: 40px 0px;
    position: relative; }
    @media (min-height: 600px) and (max-height: 800px) {
      .cart__header {
        min-height: 100px; } }
    @media only screen and (max-width: 767px) {
      .cart__header {
        padding: 20px 0; } }
    .cart__header:before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 20px;
      right: 20px;
      background-color: #656565; }
  .cart__footer {
    padding: 20px; }
    @media only screen and (min-height: 800px) {
      .cart__footer {
        min-height: 192px; } }
    @media (min-height: 600px) and (max-height: 800px) {
      .cart__footer {
        min-height: 140px; } }
    .cart__footer > div {
      margin-top: 20px; }
    @media only screen and (max-width: 767px) {
      .cart__footer {
        padding: 10px 20px; } }
  .cart__subtitle {
    line-height: 1;
    margin-top: 3px; }
  .cart__list .item {
    padding-top: 20px;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px dashed #656565; }
    @media only screen and (max-width: 576px) {
      .cart__list .item {
        flex-direction: column; } }
    .cart__list .item__img {
      width: auto;
      margin: 0 auto;
      max-height: 90px; }
      @media only screen and (max-width: 576px) {
        .cart__list .item__img {
          margin: 10px 10px 0 10px; } }
      .cart__list .item__img-wrap {
        width: 130px;
        padding: 0 10px; }
    .cart__list .item__content {
      flex-grow: 1;
      margin: 0 25px 0 20px; }
      .cart__list .item__content .flex-container:nth-child(2) {
        margin-top: 9px; }
      @media only screen and (max-width: 767px) {
        .cart__list .item__content .flex-container {
          margin: 7px 0; } }
      .cart__list .item__content .article__options:nth-child(n) {
        margin-top: 0;
        margin-right: -2px; }
    .cart__list .item__count {
      margin: 0 15px;
      font-weight: 500; }
    .cart__list .item__price {
      margin-right: auto; }
    .cart__list .item .article__toggle {
      left: auto;
      top: -10px;
      right: 30px;
      background-color: #151515; }
      @media only screen and (max-width: 767px) {
        .cart__list .item .article__toggle {
          top: -7px; } }
    .cart__list .item .icon--sous,
    .cart__list .item .icon--papper {
      margin-left: 5px; }
    @media only screen and (max-width: 576px) {
      .cart__list .item .icon--info,
      .cart__list .item .icon--remove {
        position: absolute;
        top: 10px; } }
    @media only screen and (max-width: 576px) {
      .cart__list .item .icon--info {
        left: 10px; } }
    .cart__list .item .icon--remove {
      transform: translateY(-25%); }
      @media only screen and (max-width: 576px) {
        .cart__list .item .icon--remove {
          right: 10px; } }
    .cart__list .item .cart-choose {
      margin: 15px 0 10px 0; }
      @media only screen and (max-width: 576px) {
        .cart__list .item .cart-choose {
          justify-content: center; } }
  .cart .tooltip span {
    top: 25px;
    min-width: 40px; }
  .cart__total {
    margin-right: auto;
    margin-left: 10px; }
  .cart__button {
    width: 150px;
    height: 44px;
    font-size: 12px;
    border-radius: 15px;
    border: 1px solid #FF4200; }

.map {
  position: relative;
  height: 600px;
  margin-top: -83px; }
  .map:before, .map:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 10%;
    z-index: 2; }
  .map:before {
    top: 0;
    background: #151515;
    background: linear-gradient(0deg, rgba(21, 21, 21, 0) 0%, #151515 90%); }
  .map:after {
    bottom: 0;
    background: #151515;
    background: linear-gradient(0deg, #151515 0%, rgba(21, 21, 21, 0) 50%); }

.page--contact .contact-info {
  position: relative;
  z-index: 3; }

.address {
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #656565; }
  @media only screen and (max-width: 767px) {
    .address {
      margin-bottom: 20px !important; } }
  .address__header, .address__body, .address__footer {
    padding: 10px; }
  .address__body {
    padding: 0; }
    .address__body .list {
      padding: 0 10px;
      position: relative; }
      .address__body .list--not-empty {
        padding: 10px; }
        .address__body .list--not-empty:before {
          top: 0; }
        .address__body .list--not-empty:after {
          bottom: 0; }
      .address__body .list:before, .address__body .list:after {
        content: '';
        height: 1px;
        position: absolute;
        left: 10px;
        right: 10px;
        border: 1px dashed #656565; }
      .address__body .list:before {
        top: -1px; }
      .address__body .list:after {
        bottom: -1px; }
      .address__body .list__item {
        padding: 5px 0;
        cursor: pointer; }
        .address__body .list__item .text-ellipsis {
          margin-right: 10px; }
        .address__body .list__item img {
          width: 13px;
          margin-right: 10px;
          opacity: 0.4; }
          .address__body .list__item img.location {
            width: 10px;
            margin-left: 1px;
            margin-right: 10px; }
        .address__body .list__item i {
          height: 12px;
          min-width: 8px;
          max-width: 8px;
          background-size: cover;
          margin-left: auto; }
  .address__footer i {
    opacity: 1; }

.address--error {
  color: red;
  margin-bottom: 5px; }

.adress-link {
  position: relative;
  text-align: center; }
  .adress-link span {
    display: none;
    position: absolute;
    min-width: 100px;
    top: 0;
    left: 0;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: 10px;
    border-radius: 5px;
    transform: translate(-100%, -110%); }
  .adress-link--active span {
    display: block; }

.shadow {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7); }
  .shadow--active {
    display: flex !important; }
  .shadow__wrapper {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
    .shadow__wrapper::-webkit-scrollbar {
      width: 5px; }
    .shadow__wrapper::-webkit-scrollbar-thumb {
      border: 1px solid 1px solid transparent;
      background-clip: content-box;
      background-color: #666666; }
    .shadow__wrapper::-webkit-scrollbar-track {
      border: 0;
      background-color: #d4d4d4; }
    .shadow__wrapper--for-cart {
      width: fit-content;
      transform: translateX(50%);
      margin-left: calc(50% - 500px); }

.popup {
  display: none;
  width: 100%;
  left: calc(50% - 500px);
  z-index: 10;
  margin: 20px auto;
  padding: 30px 20px;
  max-width: 520px;
  border-radius: 10px;
  background-color: #151515; }
  .popup--cart {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px;
    max-width: 100%; }
    @media only screen and (max-width: 767px) {
      .popup--cart {
        display: none !important; } }
    .popup--cart > div p {
      margin-bottom: 12px; }
  .popup--img {
    position: static;
    padding: 0;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    margin: 0 auto;
    height: 100%; }
    .popup--img .icon {
      right: 10px;
      top: 10px; }
    .popup--img img {
      max-height: 100%;
      margin: 0 auto; }
  .popup--delivery {
    max-width: 467px;
    padding: 30px; }
    .popup--delivery .icon--arrow {
      transform: rotateZ(90deg);
      opacity: 1;
      margin-left: 20px; }
  .popup--verification .popup-inner, .popup--autorization-request .popup-inner {
    position: relative; }
  .popup--autorization-request .button-wrapper {
    margin-top: 30px; }
  .popup--forgot-passw .messageCode {
    margin-bottom: 20px;
    color: #ffa500 !important; }
  .popup .choose-district {
    display: none; }
  .popup__close {
    position: absolute;
    right: -40px;
    height: 40px;
    width: 40px; }
    @media only screen and (max-width: 767px) {
      .popup__close {
        right: 0; } }
  .popup__title {
    font-weight: 600;
    text-transform: capitalize; }
  .popup__text {
    max-width: 70%; }
  .popup__list li {
    position: relative;
    margin-bottom: 20px; }
    .popup__list li:last-child {
      margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .popup .row [class^="col-"] {
      margin-bottom: 20px; }
      .popup .row [class^="col-"]:last-child {
        margin-bottom: 0; } }

.button--facebook {
  max-width: 300px;
  margin: 0 auto; }

.button--verify {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  width: auto;
  border-radius: 0 5px 5px 0;
  padding: 0 17px; }

.category-menu {
  margin: 0 auto;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto; }
  .category-menu::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .category-menu::-webkit-scrollbar-thumb {
    border: 1px solid rgba(0, 0, 0, 0);
    background-clip: content-box;
    background-color: #666666; }
  .category-menu::-webkit-scrollbar-track {
    border: 0;
    background-color: 1px solid transparent; }
  .category-menu__wrapper {
    height: 100%; }
  @media only screen and (min-width: 768px) {
    .category-menu .category--small {
      margin: 0 10px; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .category-menu .category--small {
      margin: 0 5px;
      padding: 0 5px 0 5px; } }
  @media only screen and (max-width: 767px) {
    .category-menu {
      overflow: visible;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 30px;
      z-index: 12;
      background-color: rgba(0, 0, 0, 0.9);
      transition: transform .5s;
      transform: translateX(-100%); }
      .category-menu--active {
        transform: translateX(0); }
        .category-menu--active .category-menu__show {
          transform: translateX(55%) rotateZ(180deg);
          background-position: 35% 50%; }
      .category-menu__show {
        z-index: 11;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 50%;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        transform: translateX(60%);
        background-color: inherit;
        background-repeat: no-repeat;
        background-position: 65% 50%;
        background-size: 10px 20px;
        background-image: url("../img/icon/arrow.svg"); }
      .category-menu__wrapper {
        z-index: 12;
        position: relative;
        overflow-x: auto;
        flex-wrap: wrap;
        align-items: stretch !important; }
        .category-menu__wrapper::-webkit-scrollbar {
          width: 5px; }
        .category-menu__wrapper::-webkit-scrollbar-thumb {
          border: 1px solid 1px solid transparent;
          background-clip: content-box;
          background-color: #666666; }
        .category-menu__wrapper::-webkit-scrollbar-track {
          border: 0;
          background-color: #d4d4d4; } }

/* Layout modules */
.footer {
  padding: 35px 0; }
  .footer__menu .link {
    margin: 0 25px; }
    .footer__menu .link:hover {
      color: #fff; }
  @media only screen and (max-width: 767px) {
    .footer {
      padding: 20px 0; }
      .footer .footer__info,
      .footer .footer__menu {
        flex: 1 1 100%;
        margin: 5px 0; }
      .footer .footer__info {
        order: 2; }
      .footer .footer__menu {
        order: 1;
        flex-direction: column;
        align-items: flex-start; }
        .footer .footer__menu .link {
          margin: 3px 0; } }

/* Rewrite plugins style */
/* 
* Slick Slider 
*
*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  display: flex;
  justify-content: center; }
  .slick-dots button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    color: transparent;
    margin: 0 5px;
    border: 1px solid #FF4200;
    background-color: transparent;
    box-shadow: none; }
  .slick-dots .slick-active button {
    background-color: #FF4200; }

/**** END of slick slider ****/
