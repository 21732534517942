.category-menu{
	margin: 0 auto;
	height: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	&::-webkit-scrollbar{
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb{
		border: 1px solid rgba(0,0,0,0);
		background-clip: content-box;
		background-color: lighten(#000, 40%);
	}
	&::-webkit-scrollbar-track{
		border: 0;
		background-color: 1px solid transparent;
	}
	&__wrapper{
		height: 100%;
	}
	.category--small {
		@include media-min(768) {
			margin: 0 10px;
		}
		@include media-int(768, 1199) {
			margin: 0 5px;
		    padding: 0 5px 0 5px;
		}
	}
	@include media-max(767){
		overflow: visible;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		right: 30px;
		z-index: 12;
		background-color: rgba(0,0,0,0.9);
		transition: transform .5s;
		transform: translateX(-100%);
		&--active{
			transform: translateX(0);
			.category-menu__show{
				transform: translateX(55%) rotateZ(180deg);
				background-position: 35% 50%;
			}
		}
		&__show{
			z-index: 11;
			cursor: pointer;
			position: absolute;
			right: 0;
			top: 50%;
			height: 50px;
			width: 50px;
			border-radius: 100%;
			transform: translateX(60%);
			background:{
				color: inherit;
				repeat: no-repeat;
				position: 65% 50%;
				size: 10px 20px;
				image: url('../img/icon/arrow.svg');
			}
		}
		&__wrapper{
			z-index: 12;
			position: relative;
			overflow-x: auto;
			flex-wrap: wrap;
			align-items: stretch!important;
			@include scroll(5px, #d4d4d4, 0, lighten(#000, 40%), 1px solid transparent);
		}
	}
}