.cart{
	position: fixed;
	width: 500px;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 9999999999;
	background-color: #151515;
	transition: all .5s;
	transform: translateX(110%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include media-max(576){
		width: 320px;
	}
	&--active{
		transform: translateX(0);
	}
	&__close{
		height: 40px;
		width: 40px;
		transform: translateX(-50%);
		@include media-max(576){
			transform: translateX(0);
			margin: 0 10px;
		}
	}
	&__body{
		flex-grow: 1;
		max-height: calc(100% - 307px);
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0 20px;
		@include scroll(7px, #d4d4d4, 0, #000, 1px solid transparent);
		@include media-h-int(600, 800) {
			max-height: calc(100% - 240px);			
		}
		@include media-max(767) {
		    max-height: calc(100% - 150px);
		}
		.tooltip {
			z-index: 99;
			span {
				left: 0;
				right: auto;
			}
		}
	}
	&__header{
		min-height: 115px;
		padding: 40px 0px;
		position: relative;
		@include media-h-int(600, 800) {
			min-height: 100px;
		}
		@include media-max(767) {
			padding: 20px 0;
		}
		&:before{
			content: '';
			position: absolute;
			height: 1px;
			bottom: 0;
			left: 20px;
			right: 20px;
			background-color: $second;
		}
		
	}
	&__footer{
		padding: 20px;
		@include media-h-min(800) {
			min-height: 192px;			
		}
		@include media-h-int(600, 800) {
			min-height: 140px;
		}
		& > div{
			margin-top: 20px;
		}
		@include media-max(767) {
			padding: 10px 20px;
		}
	}
	&__subtitle{
		line-height: 1;
		margin-top: 3px;
	}
	&__list{
		.item{
			padding-top: 20px;
			padding-bottom: 10px;
			position: relative;
			border-bottom: 1px dashed $second;
			@include media-max(576){
				flex-direction: column;
			}
			&__img{
				width: auto;
				margin: 0 auto;
				max-height: 90px;
				@include media-max(576){
					margin: 10px 10px 0 10px;
				}
				&-wrap {
					width: 130px;
					padding: 0 10px;
				}
			} 
			&__content{
				flex-grow: 1;
				margin: 0 25px 0 20px;
				.flex-container{
					&:nth-child(2){
						margin-top: 9px;
					}
					@include media-max(767) {
						margin: 7px 0;
					}
				}
				.article__options:nth-child(n){
					margin-top: 0;
					margin-right: -2px;
				}
			}
			&__count{
				margin: 0 15px;
				font-weight: 500;
			}
			&__price{
				margin-right: auto;
			}
			.article__toggle {
				left: auto;
				top: -10px;
				right: 30px;
			    background-color: #151515;
			    @include media-max(767) {
			    	top: -7px;
			    }
			}
			.icon--sous,
			.icon--papper{
				margin-left: 5px;
			}
			.icon--info,
			.icon--remove{
				@include media-max(576){
					position: absolute;
					top: 10px;
				}
			}
			.icon--info{
				@include media-max(576){
					left: 10px;
				}
			}
			.icon--remove{
				transform: translateY(-25%);
				@include media-max(576){
					right: 10px;
				}
			}
			.cart-choose{
				margin: 15px 0 10px 0;
				@include media-max(576){
					justify-content: center;
				}
			}
		}
	}
	.tooltip {
		span {
			top: 25px;
			min-width: 40px;
		}
	}
	&__total{
		margin: {
			right: auto;
			left: 10px;
		}
	}
	&__button{
		width: 150px;
		height: 44px;
		font-size: 12px;
		border-radius: 15px;
		border: 1px solid $primary;
	}
}