.category{
	padding: 10px 10px 0 10px;
	margin: 0 20px;
	position: relative;
	&--small{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		opacity: 0.8;
		cursor: pointer	;
		&.category-inactive {
			opacity: 0.3;
			cursor: default;
		}
		&:before,
		&:after{
			content: ''; 
			position: absolute;
			bottom: 0;
		}
		&:before{
			height: 2px;
			left: 0;
			right: 0;
			width: 100%;
			transform: scaleX(0);
			border-radius: 2px;
			background-color: $primary;
		}
		&:after {
			display: none;
			left: 50%;
			transform: translateX(-50%);
			border: 7px solid transparent;
			border-bottom: 5px solid $primary;
		}
		&:hover,
		&.category--active{
			*{
				transition: all .5s;
				opacity: 1;
			}
			&:after{
				display: block;
			}
			&:before{
				transition: all .5s;
				transform: scaleX(1);
			}
		}
		@include media-max(767){
			width: 50%;
			margin: 0;
			height: auto;
			flex: 1 1 50%;
			padding: 20px 0;
			&:before{
				width: 50%;
				margin: 0 auto;
			}
		}
		.category__wrapper{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			@include media-max(767){
				height: 70px;
			}
		}
		.category__img{
			max-width: 40px;
			max-height: 40px;
			@include media-max(767){
				opacity: 1;
				width: 70px;
			}
		}
		.category__text{
			margin-top: 10px;
			white-space: nowrap;
			font-size: 12px;
			@include media-max(767){
				opacity: 1;
			}
		}
	}
	&--big{
		max-width: 140px;
		margin: 20px 0;
		padding: 0;
		//border: 1px solid red;
		height: 110px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		cursor: pointer;
		@include media-max(767){
			max-width: 100%;
		}
		&.category-inactive {
			opacity: 0.3;
			cursor: default;
		}
		.category{
			&__wrapper{
				//border: 1px solid red;
				flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&__img{
				max-height: 80px;
				max-width: 80px;
				//border: 1px solid red;
			}
			&__text{
				//margin-top: 20px;
				//border: 1px solid red;
			}
		}
		&:hover,
		&.category--active{
			*{
				transition: all .5s;
				color: $primary;
			}
		}
	}

	&__img{
		max-width: 100%;
		margin: 0 auto;
	}
}