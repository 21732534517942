$title-color: (
	'primary': $primary, 
	'second': $second,
	'white': #fff
);
$title-size: (
	'1': 44px, 
	'2': 42px,
	'3': 38px,
	'4': 32px,
	'5': 24px,
	'6': 20px
);
$title-weight: (
	'light': 300,
	'regular': 400, 
	'medium': 500,
	'semibold': 600,
	'bold': 700
);
html[lang="en"] {
	body {
		.h1, .h2, .h3, .h4, .h5, .h6 {
			font-family: $base-font-family;		
		}
	}
}
html[lang="ge"] {
	body {
		.h1, .h2, .h3, .h4, .h5, .h6 {
			font-family: $base-ge-font-family;		
		}
	}
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6{
	color: $base-font-color;
	line-height: $base-font-line-height;
	font-weight: $base-font-weight;
	text-transform: uppercase;
	@each $status, $text-color in $title-color{
		&--#{$status}{
			color: $text-color;
		}
	}
	@each $status, $text-weight in $font-weight{
		&--#{$status}{
			font-weight: $text-weight;
		}
	}
}
@each $index, $status in $title-size{
	.h#{$index}{
		font-size: $status;
	}
}