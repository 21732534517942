@keyframes special-bg-rotate {
	from {
	  -ms-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
	  -ms-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: translate(-50%, -50%) rotate(360deg);
	}
  }