.input-wrapper{
	position: relative;
	width: 100%;
	height: 50px;
	overflow: hidden;
	border-radius: 5px;
	background-color: #191919;
	border: 1px solid $second;
	&--error,
	&--approve{
		&:after{
			content: '';
			height: 16px;
			width: 16px;
			z-index: 2;
			border-radius: 100%;
			@include absolute-center-right(10px);
			background-repeat: no-repeat;
			background-position: center center;
		}
		.input-wrapper__field{
			padding-right: 35px;
			position: relative;
			z-index: 3;
		}
	}
	&--error{
		border-color: $primary;
		&:after{
			background-image: url('../img/icon/error.svg');
		}
	}
	&--approve{
		&:after{
			background-image: url('../img/icon/approve.svg');
		}
	}
	&--new-address{
		padding-right: 100px;
		overflow: visible;
	}
	&__text{
		position: absolute;
		transform: translateY(-50%);
		z-index: 2;
		top: 50%;
		left: 15px;
		font-size: 12px;
		color: $second;
		transition: all .5s;
		&--active{
			transform: translateY(-102%);
		}
	}
	&__field{
		padding-top: 26px;
	}
	&__options{
		position: absolute;
		right: 10px;
		top: 0;
		bottom: 0;
		*{
			margin: 0 5px;
		}
		hr{
			height: 30px;
			width: 1px;
		}
		.adress-link{
			&:hover,
			&--active{
				img{
					opacity: 1;
				}
			}
			img{
				transition: all .5s;
				margin: 0;
				opacity: 0.4;
			}
		}
	}
}