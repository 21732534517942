.button{
	border-radius: 5px;
	border: 0;
	height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		transform: scale(1.05);
	}
	img{
		//opacity: 0.5;
		margin: 0 10px;
	}
	&--primary{
		color: #fff;
		border: 1px solid $second;
		background-color: #191919;
		box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.75);
		&:hover{
			border-color: $primary;
			/* img{
				opacity: 1;
			} */
		}
	}
	&--facebook{
		border: 1px solid #4667AC;
		background-color: #191919;
		position: relative;
		padding-left: 50px;
		color: #C9C9C9;
		font-size: 16px;
	    line-height: 1.5;
	    font-family: "Poppins", sans-serif;
	    font-weight: 300;
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 50px;
			background-color: #4667AC;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url('../img/icon/facebook.svg');
		}
		&:hover{
			background-color: #4667AC;
		}
	}
	&--verify{
		color: #fff;
		background-color: #3E3E3E;
		&:hover{
			background-color: darken(#3E3E3E, 5%);
		}
	}
}