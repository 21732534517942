$logo:(
	height: 56px,
	width: 56px
);

.logo{
	display: block;
	min-width: map-get($logo, width);
	height: map-get($logo, height);
	margin-right: 10px;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	&--active{	
		&:before{
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: -0.5px;
			height: 2px;
			border-radius: 2px;
			background-color: $primary;
		}
	}
}