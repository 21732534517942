.article{
	width: 100%;
	margin: 30px auto;
	max-width: 204px;
	//padding: 0 30px;
	//border: 1px solid red;
	// height: 257px;
	display: flex;
	flex-direction: column;
	@include media-max(576){
		height: auto;
		max-width: 260px;
	    border-bottom: 1px solid #656565;
	    padding: 30px 0px;
	    // margin: 0 auto;
	}
	&--special {
		position: relative;
		padding: 30px;
		max-width: 264px;
		min-height: 330px;
		border-radius: 20px;
		border: 1px solid $primary;
		background-color: #151515;
		.icon--plus-bg{
			position: absolute;
			bottom: 0;
			left: 50%;
			opacity: 1;
			margin: 0;
			transform: translate(-50%, 55%);
		}
		.icon--sous {
			margin-bottom: 0;
		}
		&__header{
			.img{
				margin-top: -20px;
				//border: 1px solid red;
			}
		}
		&__info{
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			& > *{
				margin-bottom: 10px;
				margin-top: 0;
			}
			.icon--info{
				margin-top: -2px;
			}
		}
		&__name,
		&__count{
			font-size: 13px;
		}
		&__price{
			font-size: 19px;
			margin-right: auto;
		}
		&__price-old{
			margin-right: 0;
			display: none;
		}
		&__body{
			padding: 0;
			//border: 1px solid red;
			& > *{
				margin-bottom: 10px;
			}
			.flex-container:last-child {
				margin-bottom: 0;
			}
		}
	}
	&--unavailable{
		*{
			&:not(.article__unavailable){
				color: $second;
			}
		}
		.icon,
		.img{
			pointer-events: none;
		}
	}
	&--no-options {
		.icon {
			pointer-events: none;
			opacity: 0.1;
		}
	}
	&__header{
		//border: 1px solid green;
		position: relative;
		flex-grow: 1;
		.img{
			//border: 1px solid red;
			position: relative;
			margin-top: -35px;
		    height: 204px;
		    .article__img{
		    	height: 100%;
			    background-size: contain;
			    background-repeat: no-repeat;
			    background-position: center;		    	
			}
			@include media-max(576){
				margin-top: -20px;
			}
			&--disabled{
				pointer-events: none;
			}
			&:hover{
				.article__link{
					display: block;
				}
				.article__img{
					opacity: 0.3;
				}
			}
		}
		.article__img{
			margin: 0 auto;
		}
		.article__link{
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 3;
			background:{
				repeat: no-repeat;
				position: center center;
				image: url('../img/icon/zoom.svg');
			}
		}		
	}
	&__body{
		//padding: 10px 0;
		margin-top: -30px;
		//margin-top: auto;
		//flex-grow: 1;
		//border: 1px solid red;
		.flex-container:last-child {
			min-height: 25px;
			& > p {
				line-height: 1;
			}
		}
	}
	&__options{
		position: relative;
		z-index: 2;
		.toggle-show{
			position: relative;
			z-index: 2;
		}
		.icon--sous {
			background-size: 9px;
			z-index: 2;
		}
	}
	&__toggle{
		width: 0;
		overflow: hidden;
		position: absolute;
		left: 35px;
		top: 0;
		opacity: 0;
		transition: all .5s;
		&--active{
			opacity: 1;
			width: auto;
			overflow: visible;
		}
	}
	&__count{
		margin-left: 30px;
	}
	&__info{
		z-index: 2;
		.font{
			// margin-top: 10px;
		}
	}
	&__price{

	}
	&__price-old{
	    text-decoration: line-through;
		margin:{
			right: 10px;
			left: 10px;
		}
	}
	&__unavailable{
		margin-left: auto;
	}
	&__delivery{
		&:hover{
			color: $primary;
		}
	}
	.icon{
		margin-bottom: 10px;
		margin-right: 10px;
		&--plus-bg {
			margin-bottom: 0;
		}
		&--active {
			&.remove-selection {
			    background-image: url('../img/icon/close-icon.svg');
			    background-size: 100%;
			}
		}
		&--info {
			background-position: 100% 50%;
		} 
	}
}

.tooltip{
	margin-left: auto;
	// position: relative;
	margin-bottom: 10px;
	.icon--info{
		margin: 0;
		margin-left: auto;
		position: relative;
		z-index: 2;
	}
	span{
		display: block;
		color: #fff;
		text-align: center;
		display: none;
		position: absolute;
		min-width: 80px;
		top: 30px;
		right: 0;
		line-height: 1.6;
		background-color: rgba(0,0,0,0.8);
		padding: 10px;
		font-size: 10px;
		border-radius: 5px;
	}
}