$checkbox:(
	height: 15px,
	width: 15px,
	color: $third,
	border-radius: 3px,
	background-color: rgba(0,0,0,0),
	background-color-active: $third
);
.checkbox{
	display: none;
	& + label{
		position: relative;
		padding-left: map-get($checkbox, width) + 10px;
		&:before {
			content: "";
			width: map-get($checkbox, width);
			height: map-get($checkbox, height);
			line-height: 1;
			text-align: center;
			transition: all .5s;
			border: 1px solid $third;
			@include absolute-center-left(0);
			@if (map-get($checkbox, border-radius) != 0){
				border-radius: map-get($checkbox, border-radius);
			}
			background-color: map-get($checkbox, background-color);
		}
	}
	&:checked + label:before {
		background-color: map-get($checkbox, background-color-active);
		color: map-get($checkbox, color);
	}
}
