.order{
	min-height: 70px;
	padding: 5px 0;
	border-radius: 5px;
	border: 1px solid $second;
	@include media-max(567){
		flex-direction: column;
		div{
			flex: 1 1 100%;
			width: 100%;
			text-align: left;
			img{
				margin: 0 auto;
			}
			&:after{
				display: none;
			}
		}
	}
	&__img,
	&__name,
	&__price,
	&__date{
		position: relative;
		padding: 10px;
	}
	&__img,
	&__name,
	&__price{
		&:after{
			content: '';
			height: 45px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			border-right: 1px dashed $second;
		}
	}
	&__img{
		width: 20%;
		flex: 1 1 20%;
		padding: 0 20px;
		a{
			display: block;
		}
	}
	&__name{
		width: 40%;
		flex: 1 1 40%;
	}
	&__price{
		width: 20%;
		flex: 1 1 20%;
	}
	&__date{
		width: 20%;
		flex: 1 1 20%;
		border-right: 0;
	}	
}

.order--multiply{
	@include media-max(567){
		flex-direction: column;
	}
	.order{
		border: 0;
		position: relative;
		&:before{
			content: '';
			height: 1px;
			bottom: 0;
			left: 10px;
			right: 0;
			position: absolute;
			border-top: 1px dashed $second;
			@include media-max(567){
				left: 0;
				right: 0;
			}
		}
		&:last-child{
			&:before{
				display: none;
			}
		}
	}
	div{
		&:after{
			display: none;
		}
	}
	.left{
		width: 80%;
		flex: 1 1 80%;
		@include media-max(567){
			width: 100%;
			flex: 1 1 100%;
		}
	}
	.right{
		width: 20%;
		flex: 1 1 20%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&:before{
			content: '';
			left: 0;
			top: 10px;
			bottom: 10px;
			position: absolute;
			border-right: 1px dashed $second;
		}
		@include media-max(567){
			width: 100%;
			flex: 1 1 100%;
			padding: 10px;
			align-items: flex-start;
			&:before{
				right: 0;
				top: 0;
				bottom: auto;
				border-right: none;
				border-top: 1px dashed $second;
			}
		}
	}
}