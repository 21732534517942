@mixin scroll($width, $track-color, $track-border, $thumb-color, $thumb-border){
	&::-webkit-scrollbar{
		width: $width;
	}
	&::-webkit-scrollbar-thumb{
		border: 1px solid $thumb-border;
		background-clip: content-box;
		background-color: $thumb-color;
	}
	&::-webkit-scrollbar-track{
		border: $track-border;
		background-color: $track-color;
	}
}

@mixin placeholder($color){
	&::-webkit-input-placeholder{
		color: $color;
	}
	&::-moz-placeholder{
		color: $color;
	}
	&:-ms-input-placeholder{
		color: $color;
	}
}

@mixin absolute{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}

@mixin absolute-left-top{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

@mixin absolute-center{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin absolute-center-left($left){
	position: absolute;
	left: $left;
	top: 50%;
	transform: translateY(-50%);
}

@mixin absolute-center-right($right){
	position: absolute;
	right: $right;
	top: 50%;
	transform: translateY(-50%);
}

@mixin absolute-center-top($top){
	position: absolute;
	right: 50%;
	top: $top;
	transform: translateX(-50%);
}

@mixin absolute-center-bottom($bottom){
	position: absolute;
	right: 50%;
	bottom: $bottom;
	transform: translateX(-50%);
}

@mixin shadow($color){
	position: relative;
	&:before{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
		background-color: $color;
	}
}

@mixin ellipsis{
	max-width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-align: left;
	text-overflow: ellipsis;
}

@mixin media-max($maxwidth){
	@media only screen and (max-width: $maxwidth+ "px"){
		@content
	}
}

@mixin media-min($minwidth){
	@media only screen and (min-width: $minwidth+ "px"){
		@content
	}
}


@mixin media-int($minwidth, $maxwidth) {
	@media (min-width: $minwidth + "px") and (max-width: $maxwidth + "px") {
		@content;
	}
}

@mixin media-h-max($maxheight) {
	@media only screen and (max-height: $maxheight + "px") {
		@content;
	}
}

@mixin media-h-min($minheight) {
	@media only screen and (min-height: $minheight + "px") {
		@content;
	}
}

@mixin media-h-int($minheight, $maxheight) {
	@media (min-height: $minheight + "px") and (max-height: $maxheight + "px") {
		@content;
	}
}