$nav:(
	background-color-fixed: rgba(0,0,0,0.8),
	height: 110px,
	z-index: 9
);

.nav{
	width: 100%;
	height: map-get($nav, height);
	z-index: map-get($nav, z-index);
	transition: all 0.5s ease-out 0s;
	@include media-max(767) {
		height: 100px;
	}
	.container{
		height: inherit;
		position: relative;
		padding-right: $grid-padding;
		&:before{
			content: '';
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 0;
			height: 1px;
			background-color: $second;
		}
	}
	&--default{
		position: relative;
	}
	&--fixed{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		background-color: map-get($nav, background-color-fixed);
	}
	&--default,
	&--fixed{
		@include media-max(767){
			background-color: map-get($nav, background-color-fixed);
		}
		.navigation{
			@include media-max(767){
				background-color: map-get($nav, background-color-fixed);
			}
		}
	}
	#logoutForm {
		.menu__item {
			font-size: 12px;
			font-weight: 600;
			margin: 0;
		}
	}
}