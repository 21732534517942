$menu:(
	item-margin: 0 10px,
	item-padding: 10px
);

.menu{
	margin-left: 10px;
	&__item{
		text-align: center;
		white-space: nowrap;
		margin: map-get($menu, item-margin);
		@include media-max(767) {
			margin: 0 7px;
		}
		&:first-child{
			margin-left: 0;
			@include media-max(767) {
				margin-left: 0;
			}
		}
		&:last-child{
			@include media-min(992) {
				margin-right: 0;				
			}
		}
		span {
			font-size: 12px;
			font-weight: 600;
		}
		.link{
			display: block;
			padding: map-get($menu, item-padding);
			@include media-min(767) {
				padding: 7px;
			}
			@include media-int(768, 991) {
				padding: 0;
			}
			@include media-max(767) {
				padding: 0 10px;
			}
			&:hover,
			&--active{
				color: $primary;
				svg{
					fill: $primary;
					transition: all .5s;
				}
			}
			span{
				display: block;
			}
			svg{
				display: block;
				margin: 10px auto;
				fill: $base-font-color;
			}
		}
		&.lang__switcher {
			padding-top: 5px;
		    min-width: 18px;
			a {
				&:first-child {
					margin-bottom: 13px;
				}
				&.active, &:hover {
					font-weight: 700;
					color: $primary;
				}
			}
		}
	}
}