.blug-bg {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-image: url('../img/coming-soon.jpg');
	background-size: cover;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
	h1 {
		font-size: 70px;
		color: white;
		text-transform: uppercase;
		text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
	}
}

body{
	&:before,
	&:after{
		content: '';
		position: fixed;
		z-index: 0;
		background: {
			size: cover;
			repeat: no-repeat;
			position: center center;
		}
	}
	&:before{
		width: 130px;
		height: 230px;
		top: -130px;
		right: -10px;
		background-image: url('../img/body-top.svg');
	}
	&:after{
		width: 200px;
		height: 250px;
		left: -20px;
		bottom: -130px;
		background-image: url('../img/body-bottom.svg');
	}
	*{
		z-index: 1;
	}
	.cloned__article-img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 99;
		height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
		background-position: top center;
	}	
}


.container-fluid,
.container,
.section,
.main {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.container {
	padding: 0 15px 0 15px;
	min-width: $min-width;
	max-width: $max-width;
	@include media-min(1200){
		padding: 0 15px;
	}
}

.main{
	padding: 20px 0;
	&__nav{
		padding-bottom: 20px;
	}
}
.call-us {
	position: fixed;
	cursor: pointer;
	z-index: 99;
	display: flex;
	align-items: center;
	bottom: 90px;
	left: 25px;
	@include media-max(992) {
		bottom: 10px;
		left: 10px;
	}
	.call-icon {
		display: flex;
		border: 4px solid #FF4200;
		padding: 15px;
		border-radius: 50%;
		margin-right: 14px;
		@include media-max(992) {
			padding: 10px;
			background-color: #151515;
		}
	}
	&:hover {
		p.font {
			display: block;
		}
	}
	p.font {
		display: none;
	}
}
.district {
	display: none;
	overflow-y: auto;
	max-height: 200px;
    padding: 10px;
	border-radius: 5px;
	border: 1px solid #656565;
    background-color: #191919;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
    @include scroll(5px, #d4d4d4, 0, lighten(#000, 40%), 1px solid transparent);
    @include absolute-left-top;
    top: 53px;
    z-index: 10;
    @include media-max(991) {
		max-height: 265px;
    }
    @include media-h-min(650) {
    	min-height: 305px;
    }
    @include media-h-min(750) {
		min-height: 345px;
    }
    &__item {
	    padding: 7px 0;
	    text-align: center;
	    cursor: pointer;
	    &:hover {
	    	color: $primary;
	    }		    	
    }
}

.page{
	@include media-max(768){
		position: relative;
	}
	&--main{
		height: 100vh;
		// min-height: 720px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include media-max(991){
			height: auto;
			min-height: 100vh;
			overflow-y: auto;
		}
		.special-offer-wrap {
			@include media-max(991) {
				// position: absolute;
				left: 0;
				right: 0;
				// height: 100vh;
				width: 100%;
			    display: flex;
			    justify-content: center;
			    align-items: flex-start;
			    // background: #151515;
				padding-top: 70px;
			}
			.special-offer-close {
				// display: none;
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
				@include media-max(991) {
				    display: inline-block;
				    right: 20%;
				    position: absolute;
				    font-size: 48px;
				    font-family: 'Poppins';
					line-height: 48px;
				}
				@include media-max(768) {
				    right: 5%;
				}
			}
		}
		.special-offer {
			transition: all 1s;
			position: relative;
			@include media-max(991) {
				max-width: 100%;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 492px;
				height: 777px;
				background: transparent url("../img/special-bg.png") no-repeat center;
				background-size: contain;
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
				animation: special-bg-rotate 3s linear;
			}
			.article {
				@include media-max(991) {
					width: 264px;
				}
				@include media-max(768) {
					margin-bottom: 20px;
				}
			}
			.text-center {
				@include media-max(991) {
					width: 70%;
					margin: 0 auto;
				}
				@include media-max(768) {
					width: 90%;

				}
				.font {
					&--md {
						margin-bottom: 5px;
					}
					&--sm {
						padding: 0 15%;
					}
				}
			}
		}
		.main{
			.container{
				position: relative;
				&:before{
					content: 'Mochi';
					position: absolute;
					left: -100px;
					right: 0;
					top: 50%;
					// bottom: 0;
					font-family: "Poppins";
					font-size: 310px;
					font-weight: bold;
					color: #fff;
					opacity: 0.03;
					transform: translateY(-50%);
					@include media-max(767){
						background-image: none;
					}
				}
				.row--big{
					min-height: 400px;
					// max-height: 420px;
					// overflow: hidden;
					background-image: url('../img/category/img/0.png');
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				    transition: ease-in-out 0.5s;
					@include media-max(991){
						background-image: none;
						// position: absolute;
						// top: 0;
						width: 100%;
					}
				}
				.img--big{
					// width: 100%;
					max-width: 400px;
					max-height: 400px;
					transition: all 1s;
					margin-right: 0;
					margin-left: auto;
				}
			}
		}
	}
	&--profile{
		.order {
			&__name {
				align-items: center;
			}
		}
		.tab {
			max-width: 700px;
			&__body {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&--category{
		.sort {
			margin-top: 5px;
		}
		.articles-wrapper {
			@include media-min(1200) {
				margin: 0 -40px;
			}
			.article {
				&__body {
					& > div:first-child {
						margin-bottom: 10px;
					}
				}
				&__name, &__price {
					font-weight: 600;
				}
				.icon--plus-bg {
					margin-right: 0;
				}
			}
		}
	}
	&--order{
		.popup__list-order{
			max-width: 500px;
			margin: 0 auto;
			li{
				@include media-max(767){
					margin-bottom: 0;
					& > *{
						margin: 10px 0;
					}
					&:last-child{
						.button{
							margin: 10px 0;
						}
					}
				}
			}
		}
		.address__footer {
			& > p {
				@include media-max(350) {
					font-size: 10px;
				}
			}
		}
		.delivery-btn-row {
			z-index: 8;
			.delivery-btn-wrap {
				z-index: 8;
			}
			.district {
				max-width: 90%;
			    left: 5%;
			}
		}
	}
	&--contact{
		.contact-info{
			margin-bottom: 10px;
			& > *{
				margin: 10px 43px 10px 0;
				img{
					margin-right: 10px;
				}
			}
		}
	}
	&--category, &--ty, &--404, &--contact, &--faq, &--order, &--profile {
		.main-wrapper {
			min-height: calc(100vh - 106px);			
		}
	}
	&--thankyou {
		.ty-inner {
			padding: 30px 10px;
			h1 {
				&:first-child {
					margin-bottom: 20px;
				}
			}
		}
	}
}

.hr{
	display: block;
	&--vertical{
		height: 50px;
		width: 2px;
		margin: 0 20px;
		background-color: $second;
	}
	&--line{
		width: 100%;
		height: 2px;
		margin-left: 35px;
		margin-right: 10px;
		background-color: $second;
	}
	&--dashed{
		width: 100%;
		height: 1px;
		border-bottom: 1px dashed $second;
		&:first-child{
			margin-right: 5px;
		}
		&:last-child{
			margin-left: 5px;
		}
	}
}