.field{
	resize: none;
	border: 0;
	width: 100%;
	height: 100%;
	color: #fff;
	padding: 10px 15px;
	background-color: rgba(0,0,0,0);
}
// input.field:-webkit-autofill,
// input.field:-webkit-autofill:hover, 
// input.field:-webkit-autofill:focus, 
// input.field:-webkit-autofill:active  {
//     -webkit-text-fill-color: #fff;
//     transition: background-color 5000s ease-in-out 0s;
// }