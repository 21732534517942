.faq{
	cursor: pointer;
	border: 1px solid $second;
	border-radius: 10px;
	margin: 20px 0 20px 0;
	padding: 20px 20px 20px 30px;
	@include media-max(767){
		padding: 10px;
	}
	&:first-child{
		margin-top: 0;
	}
	&:last-child{
		margin-bottom: 0;
	}
	&--active{
		.faq__header{
			&:after{
				transform: rotateZ(270deg);
			}
		}
	}
	&__header{
		position: relative;
		padding-right: 15px;
		&:after{
			content: '';
			position: absolute;
			background-size: cover;
			background-repeat: no-repeat;
			background-image: url('../img/icon/arrow.svg');
			height: 10px;
			width: 6px;		
			top: 6px;
			right: 0;
			transition: all .5s;
			transform: rotateZ(90deg);
		}
		hr{
			height: 20px;
			width: 1px;
			margin: 0 20px;
			background-color: $second;
			@include media-max(767){
				margin: 0 10px;
			}
		}
	}
	&__body{
		padding: 10px 55px 0 55px;
		display: none;
		@include media-max(767){
			padding: 10px;
		}
	}
}