$min-width: 320px;
$max-width: 1130px;

$base-font-size: 14px;
$base-font-color: #C9C9C9;
$base-font-weight: 300;
$base-font-line-height: 1.5;
$base-font-family: 'Poppins', sans-serif;
$base-ge-font-family: 'firago', sans-serif;

$base-background-color: #151515;
$base-border-color: #C9C9C9;
$base-transition: all 0.5s;

$base-grid-padding: 15px;
$base-grid-margin: 15px;
$base-grid-column: 12;

$primary: #FF4200;
$second: #656565;
$third: #C9C9C9;