$popup:(
	z-index: 10,
	max-width: 520px,
	margin: 20px auto,
	padding: 30px 20px,
	border-radius: 10px,
	color-background: #151515
);
.popup{
	display: none;
	width: 100%;
	left: calc(50% - 500px);
	z-index: map-get($popup, z-index);
	margin: map-get($popup, margin);
	padding: map-get($popup, padding);
	max-width: map-get($popup, max-width);
	border-radius: map-get($popup, border-radius);
	background-color: map-get($popup, color-background);
	&--cart{
		background-color: rgba(0,0,0,0);
		padding: 10px;
		max-width: 100%;
		@include media-max(767){
			display: none!important;
		}
		& > div {
			p {
				margin-bottom: 12px;
			}
		}
	}
	&--img{
		position: static;
		padding: 0;
		// max-width: fit-content;
		border-radius: 0;
		background-color: rgba(0,0,0,0);
		margin: 0 auto;
		height: 100%;
		.icon{
			right: 10px;
			top: 10px;
		}
		img{
			max-height: 100%;
			margin: 0 auto;
		}
	}
	&--delivery{
		max-width: 467px;
		padding: 30px;
		.icon--arrow{
			transform: rotateZ(90deg);
			opacity: 1;
			margin-left: 20px;
		}
	}
	&--verification, &--autorization-request {
		.popup-inner {
			position: relative;
		}
	}
	&--autorization-request {
		.button-wrapper {
			margin-top: 30px;
		}
	}
	&--forgot-passw {
		.messageCode {
			margin-bottom: 20px;
			color: #ffa500 !important;
		}
	}
	.choose-district{
		display: none;
	}
	&__close{
		position: absolute;
		right: -40px;
		height: 40px;
		width: 40px;
		@include media-max(767){
			right: 0;
		}
	}

	&__title{
		font-weight: 600;
		text-transform: capitalize;
	}
	&__text{
		max-width: 70%;
	}

	&__list{
		li{
			position: relative;
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.row [class^="col-"]{
		@include media-max(767){
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}
.button--facebook{
	max-width: 300px;
	margin: 0 auto;
}
.button--verify{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 15px;
	width: auto;
	border-radius: 0 5px 5px 0;
	padding: 0 17px;
}