.sort{
	margin-top: 40px;
	&__item{
		margin-right: 30px;
		&:hover,
		&--active{
			text-decoration: underline;
			color: $primary;
		}
	}
}