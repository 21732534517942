.map{
	position: relative;
	height: 600px;
	margin-top: -83px;
	&:before,
	&:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		height: 10%;
		z-index: 2;
	}
	&:before{
		top: 0;
		background: rgb(21,21,21);
		background: linear-gradient(0deg, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 90%);
	}
	&:after{
		bottom: 0;
		background: rgb(21,21,21);
		background: linear-gradient(0deg, rgba(21,21,21,1) 0%, rgba(21,21,21,0) 50%);
	}
}
.page--contact {
	.contact-info {
		position: relative;
		z-index: 3;
	}
}