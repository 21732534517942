.address{
	overflow: hidden;
	border-radius: 5px;
	border: 1px solid $second;
	@include media-max(767){
		margin-bottom: 20px!important;
	}
	&__header,
	&__body,
	&__footer{
		padding: 10px;
	}
	&__body{
		padding: 0;
		.list{
			padding: 0 10px;
			position: relative;
			&--not-empty{
				padding: 10px;
				&:before{
					top: 0;
				}
				&:after{
					bottom: 0;
				}
			}
			&:before,
			&:after{
				content: '';
				height: 1px;
				position: absolute;
				left: 10px;
				right: 10px;
				border: 1px dashed $second;
			}
			&:before{
				top: -1px;
			}
			&:after{
				bottom: -1px;
			}
			&__item{
				padding: 5px 0;
				cursor: pointer;
				.text-ellipsis{
					margin-right: 10px;
				}
				img{
					width: 13px;
					margin-right: 10px;
					opacity: 0.4;
					&.location{
						width: 10px;
						margin-left: 1px;
						margin-right: 10px;
					}
				}
				i{
					height: 12px;
					min-width: 8px;
					max-width: 8px;
					background-size: cover;
					margin-left: auto;
				}
			}
		}
	}
	&__footer{
		i{
			opacity: 1;
		}
	}
}

.address--error{
	color: red;
	margin-bottom: 5px;
}
.adress-link{
	position: relative;
	text-align: center;
	span{
		display: none;
		position: absolute;
		min-width: 100px;
		top: 0;
		left: 0;
		line-height: 1;
		background-color: rgba(0,0,0,0.8);
		padding: 10px;
		font-size: 10px;
		border-radius: 5px;
		transform: translate(-100%, -110%);
	}
	&--active{
		span{
			display: block;
		}
	}
}