@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700');

@font-face {
    font-family: "firago";
    src: url("../fonts/firago/FiraGO-Light.eot?v=2.0.1#iefix") format("embedded-opentype"),
    url("../fonts/firago/FiraGO-Light.woff") format("woff"),
    url("../fonts/firago/FiraGO-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "firago";
    src: url("../fonts/firago/FiraGO-Regular.eot?v=2.0.1#iefix") format("embedded-opentype"),
    url("../fonts/firago/FiraGO-Regular.woff") format("woff"),
    url("../fonts/firago/FiraGO-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "firago";
    src: url("../fonts/firago/FiraGO-Medium.eot?v=2.0.1#iefix") format("embedded-opentype"),
    url("../fonts/firago/FiraGO-Medium.woff") format("woff"),
    url("../fonts/firago/FiraGO-Bold.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "firago";
    src: url("../fonts/firago/FiraGO-SemiBold.eot?v=2.0.1#iefix") format("embedded-opentype"),
    url("../fonts/firago/FiraGO-SemiBold.woff") format("woff"),
    url("../fonts/firago/FiraGO-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "firago";
    src: url("../fonts/firago/FiraGO-Bold.eot?v=2.0.1#iefix") format("embedded-opentype"),
    url("../fonts/firago/FiraGO-Bold.woff") format("woff"),
    url("../fonts/firago/FiraGO-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}