.tab{
	max-width: 600px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 20px;
	.text-left{
		width: 10%;
		flex: 1 1 10%;
		@include media-max(767){
			flex: 1 1 100%;
			width: 100%;
			margin-bottom: 20px;
		}
	}
	.hr.hr--vertical{
		@include media-max(767){
			display: none;
		}
	}

	.link{
		width: 30%;
		flex: 1 1 30%;
		opacity: 0.4;
		margin-right: 20px;
		@include media-max(576){
			flex: 1 1 100%;
			width: 100%;
			margin: 10px 0!important;
			&--first{
				order: 1;
			}
			&--second{
				order: 3;
			}
		}
		&:hover,
		&--active{
			opacity: 1;
		}
	}

	&__body{
		display: none;
		width: 100%;
		flex: 1 1 100%;
		padding: 20px;
		@include media-max(767){
			margin: 10px 0;
			padding: 0 10px;
			&#tab-profile{
				order: 4;
			}
			&#tab-order{
				order: 2;
			}
		}
		.row [class^="col-"]{
			@include media-max(767){
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}