.footer{
	padding: 35px 0;
	&__menu{
		.link{
			margin: 0 25px;
			&:hover{
				color: #fff;
			}
		}
	}
	@include media-max(767){
		padding: 20px 0;
		.footer__info,
		.footer__menu{
			flex: 1 1 100%;
			margin: 5px 0;
		}
		.footer__info{
			order: 2;
		}
		.footer__menu{
			order: 1;
			flex-direction: column;
			align-items: flex-start;
			.link{
				margin: 3px 0;
			}
		}
	}
}