$shadow:(
	color-background: rgba(0,0,0,0.7),
	z-index: 9
);
.shadow{
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	z-index: map-get($shadow, z-index);
	background-color: map-get($shadow, color-background);
	&--active{
		display: flex!important;
	}
	&__wrapper{
		max-height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		@include scroll(5px, #d4d4d4, 0, lighten(#000, 40%), 1px solid transparent);
		&--for-cart{
			width: fit-content;
			transform: translateX(50%);
			margin-left: calc(50% - 500px);
		}
	}
}