$breadcrumbs:(
	margin: 10px 0,
	color-all: #5A5A5A,
	color-all-hover: #FFF,
	color-last: #FFF,
	text-decoration: false
);

.breadcrumbs{
	margin: map-get($breadcrumbs, margin);
	&__item{
		padding: 0 20px;
		position: relative;
		&:hover,
		&--active{
			&:not(:last-child){
				color: map-get($breadcrumbs, color-all-hover);
				@if(map-get($breadcrumbs, text-decoration) == true){
					text-decoration: underline;
				}
			}
		}
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
			color: map-get($breadcrumbs, color-last);
		}
		&:not(:last-child){
			color: map-get($breadcrumbs, color-all);
			&:after{
				content: '';
				width: 10px;
				height: 20px;
				background:{
					repeat: no-repeat;
					position: center center;
					image: url('../img/icon/arrow.svg');
				}
				@include absolute-center-right(-7px)
			}
		}
	}
}