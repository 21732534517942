html[lang="en"] {
	body {
		font-family: $base-font-family;		
	}
}
html[lang="ge"] {
	body {
		font-family: $base-ge-font-family;		
	}
}
body{
	margin: 0;
	padding: 0;
	text-transform: none;
	color: $base-font-color;
	line-height: $base-font-line-height;
	font-weight: $base-font-weight;
	font-size: $base-font-size;
	background-color: $base-background-color;
	overflow-x: hidden;
	overflow-y: auto;
	@include scroll(10px, #d4d4d4, 0, lighten(#000, 40%), 1px solid transparent);
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, 
form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside,  details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main{
	display: block;
}
blockquote, q{
	quotes: none;
	&:before,
	&:after{
		content: '';
		content: none;
	}
}
marquee{
	width: 100%;
}
ul,li{
	list-style: none;
}
html{
	height: 100%;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
}
input[type="text"],
input[type="password"],
input[type="email"],
inputm
textarea{
	outline: none;
	appearance: none;
	border-radius: 0;
}
input[type="button"], 
input[type="submit"], 
input[type="reset"], 
button, 
label,
a{
	outline: none;
	cursor: pointer;
	transition: $base-transition;
}
img{
	max-width: 100%;
	height: auto;
	display: block;
}
table{
	width: 100%;
}
td,
tr,
form{
	margin: 0;
	padding: 0;
}
a{
	color: $base-font-color;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}	
a,
span,
div,
button{
	outline: none!important;
}

hr{
	border: 0;
	width: 100%;
	height: 1px;
	margin: 10px 0;
	background-color: #000;
}

*{
	box-sizing: border-box;
	&:before,
	&:after{
		box-sizing: border-box;
	}
}