$font-color: (
	'primary': $primary,
	'second': $second,
	'green': #91D176,
	'white': #fff
);

$font-size: (
	'sm': 12px, 
	'md': 16px,
	'lg': 18px
);
$font-weight: (
	'light': 300,
	'regular': 400, 
	'medium': 500,
	'semibold': 600,
	'bold': 700
);
html[lang="en"] {
	body {
		.font {
			font-family: $base-font-family;		
		}		
	}
}
html[lang="ge"] {
	body {
		.font {
			font-family: $base-ge-font-family;		
		}		
	}
}
.font{
	color: $base-font-color;
	line-height: $base-font-line-height;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	@each $status, $text-color in $font-color{
		&--#{$status}{
			color: $text-color;
		}
	}
	@each $status, $text-weight in $font-weight{
		&--#{$status}{
			font-weight: $text-weight;
		}
	}
	@each $status, $text-size in $font-size{
		&--#{$status}{
			font-size: $text-size;
		}
	}
}